import React, { Component } from 'react';
import Select from 'react-select';
import Loading from 'react-loading';

interface IProps {
  addressNo: any,
  addressList: any,
  displayAddress: any,
  companyName: string,
  userName: string,
}
const SelectShippingAddress: React.FC<IProps> = props => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [addressNo, setAddressNo] = React.useState(props.addressNo)
  const [addressList, setAddressList] = React.useState(props.addressList)
  const [companyName, setCompanyName] = React.useState(props.companyName)
  const [userName, setUserName] = React.useState(props.userName)
  const [displayAddress, setDisplayAddress] = React.useState(props.displayAddress)
  React.useEffect(() => {
    if (!addressNo){
      return
    }
    fetch(`/shipping_infos?shipping_infos_id=${addressNo.value}`, {
      method: "get"
    }).then((response) => {
      if (!response.ok) {
          throw new Error();
      }
      return response.json()
    }).then((json) => {
      setCompanyName(json.data.company_name)
      setUserName(json.data.user_name)
      setDisplayAddress(json.data.shipping_address)
    })
  }, [addressNo])

  const handleChange = (option: any) => {
    setAddressNo(option);
  };

  return (
    <div className="shipping-address-container">
      <div className="border-deep-b.d-flex.pb-2.mb-3">配送先</div>
      <div className="shipping-address">
        <div className="shipping-address-left-w">
          <div className="py-2">
            <div className="d-inline">会社名：</div> 
            <div className="d-inline">{companyName}</div> 
          </div>
          <div className="py-2">
            <div className="d-inline">担当者：</div>
            <div className="d-inline">{userName}</div>
          </div>
          <div className="py-1">
            {displayAddress}
          </div>
        </div>
        <div className="shipping-address-right-hidden">
          <div className="shipping-address-right-mt">配送先を選択</div>
          { addressList &&
              <Select
                className="w80"
                value={addressNo}
                defaultValue={addressList[0]}
                onChange={option => handleChange(option)}
                name="reservation[shipping_info_id]"
                options={addressList}
              />
          }
          <div className="mt-4">
            <div className="d-inline">配送先の追加、変更はマイページの</div>
            <a className="text-blue d-inline" target="_blank" href="/mypage/shipping_infos/new">設定</a>
            <div className="d-inline">から行います。</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectShippingAddress