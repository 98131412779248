import React, { useRef, useState } from "react"
import { Oval } from "react-loader-spinner"

interface IProps {
  object_type: string
  id: string
  files: any[]
  file_names: string[]
}
const PdfUpload: React.FC<IProps> = props => {
  const [uploading, setUploading] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const fileInputRef: any = useRef()
  const modalRef: any = useRef()
  const [fileObjects, setFileObjects] = useState<any[]>(props.files ?? [])
  const [fileNames, setFileNames] = useState<File[] | string[]>(props.file_names ?? [])
  const maxNumber = 5

  const onDragOver = e => e.preventDefault()
  const onDragEnter = e => e.preventDefault()
  const onDragLeave = e => e.preventDefault()

  const onFileInputClick = () => fileInputRef.current.click()

  const onFileDrop = (e) => {
    e.preventDefault()
    if (fileNames.length >= maxNumber) {
      alert(`登録できるファイルは最大${maxNumber}ファイルです。`)
      return
    }
    const files = e.dataTransfer.files
    if (files.length) {
      handleFiles(files)
    }
  }

  const onFilesChange = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files)
    }
  }

  const handleFiles = async (files: FileList) => {
    if (uploading || deleting) return

    const fileArr = Array.from(files)
    if ((fileNames.length + fileArr.length) > maxNumber) {
      alert(`登録できるファイルは最大${maxNumber}ファイルです。`)
      return
    }
    if (fileArr.some(s => !s.name.includes("pdf"))) {
      alert("PDFファイルのみアップロード可能です。")
      return
    }
    if (fileArr.some(f => f.size > (1024 * 1024 * 8))) {
      alert("8MB以内のPDFを登録してください。")
      return
    }

    setUploading(true)

    const formData = new FormData()
    fileArr.forEach(f => formData.append(`listing[listing_files_attributes][][file]`, f))
    try {
      const result = await fetch(`${window.location.origin}/${props.object_type}/${props.id}/upload_file`, {
        method: "put",
        headers: { "X-CSRF-Token": document.getElementsByName("csrf-token")[0].getAttribute("content") },
        body: formData,
      })
      const reader = result.body.getReader()
      const decoder = new TextDecoder()
      let resultText = ""
      let flag = false
      while (!flag) {
        const data = await reader.read()
        flag = data.done
        resultText += decoder.decode(data.value)
      }
      const resultObj = JSON.parse(resultText)
      setFileNames(resultObj.names)
      setFileObjects(resultObj.files)
    } catch (e) {
      if (e.message.includes("Unexpected token < in JSON at position 0")){
        alert("アップロードするデータのファイル名は半角英数字のみにして下さい。")
        return
      }
    } finally {
      setUploading(false)
    }
  }

  const onClickDelete = async (index: number) => {
    if (uploading || deleting) return
    if (!confirm("削除しますか？")) return

    setDeleting(true)
    const formData = new FormData()
    formData.append(`listing[listing_files_attributes][][id]`, fileObjects[index].id)
    formData.append(`listing[listing_files_attributes][][_destroy]`, "true")
    try {
      await fetch(`${window.location.origin}/${props.object_type}/${props.id}/upload_file`, {
        method: "put",
        headers: { "X-CSRF-Token": document.getElementsByName("csrf-token")[0].getAttribute("content") },
        body: formData,
      })
      setFileNames(prev => (prev as any[]).filter((_, i) => i !== index))
      setFileObjects(prev => (prev as any[]).filter((_, i) => i !== index))
    } catch (e) {
      console.log(e)
      // if (e.message.includes("Unexpected token < in JSON at position 0")){
      //   alert("アップロードするデータのファイル名は半角英数字のみにして下さい。")
      // }
    } finally {
      setDeleting(false)
    }
  }

  const disabled = maxNumber - fileNames.length <= 0

  return (
    <>
      <div className="image-c">
        <div className="upload__image-wrapper">
        <div className="sub-container pdf-sub">
            {fileNames.map((fileName, i) => (
              <div key={i} className={`w52 mt-3 ${i > 4 ? "hidden" : ""}`} >
                <div className="w28 mx-auto pb-3 pdf-icon">
                  <i className="far fa-file-pdf  fa-5x text-red"></i>
                  <span onClick={() => onClickDelete(i)}>
                    <i className="fas fa-times" />
                  </span>
                </div>
                <p className="file-name">{fileName}</p>
              </div>
            ))}
            <div ref={modalRef} />
            <div className="image-item pdf-remain">
              <div className="remaining h32">
                <div className="remaining-text-pdf">
                  <p className="text-center">残り</p>
                  <div className="text-center">
                    <p className="d-inline">
                      {maxNumber - fileNames.length <= 0
                        ? 0
                        : maxNumber - fileNames.length}
                    </p>
                    <p className="d-inline">ファイル</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="upload_container"
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onFileDrop}
            onClick={onFileInputClick}
          >
            <div className="bracket-container bracket-pdf" >
              <div className="d-flex justify-content-between">
                <div className="bracket_lt" />
                <div className="bracket_rt" />
              </div>
              <div className={`image-a image-a-pdf ${disabled ? "text-hidden" : ""}`}>
                <div className="w28 mx-auto mb-3">
                  <i className="far fa-file-pdf  fa-5x" />
                </div>
                <p>ドラック＆ドロップまたはクリックしファイルをアップロード</p>
                <p>最大{maxNumber}ファイルまでアップロードできます</p>
              </div>
              <div className="d-flex justify-content-between">
                <div className="bracket_lb" />
                <div className="bracket_rb" />
              </div>
              <div>
                <input
                  ref={fileInputRef}
                  className="file-input"
                  type="file"
                  multiple
                  onChange={onFilesChange}
                  accept=".pdf"
                  disabled={disabled}
                  hidden
                />
              </div>
            </div>
          </div>
          <div className="sub-container">
            <p>※8MB以内のPDFを登録してください。</p>
            {/* <p>※アップロードするデータのファイル名は半角英数字のみにして下さい。</p> */}
          </div>
        </div>
      </div>

      {(uploading || deleting) &&
        <div className="pdf_upload_loading">
          <Oval
            ariaLabel="loading-indicator"
            height={100}
            width={100}
            strokeWidth={5}
            color="#00aa6e"
            secondaryColor="white"
          />
          <p>{
            uploading
              ? "アップロード中..."
              : deleting
                ? "削除中..."
                : ""
          }</p>
        </div>
      }
    </>
  )
}

export default PdfUpload
