import React from 'react'
import Select from 'react-select'

interface IProps {
  signd_in: boolean,
  mainCategoryList: any[],
  subCategoryList: any[],
  selectedMainCategory: number,
  selectedSubCategory: number,
  selectedDefalutMainCategory: any,
  selectedDefalutSubCategory: any,
}
const SelectCategory: React.FC<IProps> = props => {
  const [mainCategoryList, setMainCategoryList] = React.useState<any[]>(props.mainCategoryList)
  const [subCategoryList, setSubCategoryList] = React.useState<any[]>(props.subCategoryList)
  const [selectedMainCategory, setSelectedMainCategory] = React.useState<number>(props.selectedDefalutMainCategory.value)
  const [selectedSubCategory, setSelectedSubCategory] = React.useState<any>(props.selectedDefalutSubCategory)

  React.useEffect(() => {
    setSubCategoryList(null)
    if (selectedMainCategory == 1 || selectedMainCategory == 2 || selectedMainCategory == 4 || selectedMainCategory == 5 || selectedMainCategory == 7) {
      let elements = document.getElementsByClassName("category-display-pattern-1")
      for(var i=0;i<elements.length;i++){
        elements[i].style.display= "block";
      }
      elements = document.getElementsByClassName("category-display-pattern-2")
      for(var i=0;i<elements.length;i++){
        elements[i].style.display= "none";
      }
      elements = document.getElementsByClassName("category-display-pattern-block")
      for(var i=0;i<elements.length;i++){
        elements[i].style.display= "block";
      }
    } else if (selectedMainCategory == 6) {
      let elements = document.getElementsByClassName("category-display-pattern-1")
      for(var i=0;i<elements.length;i++){
        elements[i].style.display= "none";
      }
      elements = document.getElementsByClassName("category-display-pattern-2")
      for(var i=0;i<elements.length;i++){
        elements[i].style.display= "block";
      }
      elements = document.getElementsByClassName("category-display-pattern-block")
      for(var i=0;i<elements.length;i++){
        elements[i].style.display= "block";
      }
    } else {
      let elements = document.getElementsByClassName("category-display-pattern-1")
      for(var i=0;i<elements.length;i++){
        elements[i].style.display= "none";
      }
      elements = document.getElementsByClassName("category-display-pattern-2")
      for(var i=0;i<elements.length;i++){
        elements[i].style.display= "none";
      }
      elements = document.getElementsByClassName("category-display-pattern-block")
      for(var i=0;i<elements.length;i++){
        elements[i].style.display= "none";
      }
    }

    if (selectedMainCategory == 999) {
      // その他の場合はサブカテゴリー不要なため
        setSubCategoryList(null)
    } else {
      fetch(`/categories?parent_id=${selectedMainCategory}`, {
        method: "get"
      }).then((response) => {
        if (!response.ok) {
            throw new Error();
        }
        return response.json()
      }).then((json) => {
        setSubCategoryList(json.data)
      })
    }
  }, [selectedMainCategory])

  const onChange = async (selectItem: any) => {
    setSelectedMainCategory(selectItem.value as number)
    setSelectedSubCategory({label: "サブカテゴリー", value: 0})
  }

  return (
    <div className="category-wrap select-double">
      <Select
        options={mainCategoryList}
        placeholder="メインカテゴリー"
        name="listing[main_category_id]"
        onChange={onChange}
        className="select-w mr-4"
        defaultValue={props.selectedDefalutMainCategory}
        />
      {subCategoryList &&
        <Select
          options={subCategoryList}
          placeholder="サブカテゴリー"
          name="listing[sub_category_id]"
          className="select-w"
          defaultValue={selectedSubCategory} />
      }
    </div>
  )
}

export default SelectCategory
