import React from 'react'
import Select from 'react-select'

interface IProps {
  signd_in: boolean,
  prefectureList: any[],
  cityList: any[],
  listingObject: any
}
const InputPrefecture: React.FC<IProps> = props => {
  const [prefectureList, setPrefectureList] = React.useState<any[]>(props.prefectureList)
  const [cityList, setCityList] = React.useState<any[]>(props.cityList)
  const [selectedPrefecture, setSelectedPrefecture] = React.useState<any>(
    () => {
      if (props.listingObject.prefecture_name) {
        return { label: props.listingObject.prefecture_name, value: props.listingObject.prefecture_name }
      } else {
        return ""
      }
    }
  )
  const [selectedCity, setSelectedCity] = React.useState<any>(
    () => {
      if (props.listingObject.city_name) {
        return { label: props.listingObject.city_name, value: props.listingObject.city_name }
      } else {
        return ""
      }
    }
  )
  
  React.useEffect(() => {
    if ( selectedPrefecture && selectedPrefecture.value != undefined ) {
      setCityList(null)

      fetch(`/cities?prefecture_name=${selectedPrefecture.value}`, {
        method: "get"
      }).then((response) => {
        if (!response.ok) {
            throw new Error();
        }
        return response.json()
      }).then((json) => {
        setCityList(json.data)
      })
    } else if (!selectedPrefecture) {
      setCityList(null)
    }
  }, [selectedPrefecture])

  const onChange = async (selectItem: any) => {
    setSelectedPrefecture(selectItem)
    setSelectedCity("")
  }
  
  return (
    <div>
      <Select 
        isClearable
        options={prefectureList} 
        name="listing[prefecture_name]" 
        onChange={onChange} 
        className="d-block"
        placeholder="都道府県を入力"
        defaultValue={selectedPrefecture}
        />
      {cityList &&
        <Select 
          isClearable
          options={cityList} 
          name="listing[city_name]" 
          className="d-block mt-16-8px"
          placeholder="市区町村を選択"
          defaultValue={selectedCity} />
      }
    </div>
  )
}

export default InputPrefecture
