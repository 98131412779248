import React, { Component } from 'react';
import Select from 'react-select';

interface IProps {
  signd_in: boolean,
  makerList: any[],
  selectedMaker: any,
  searchObject: any,
}

const SearchSelectMaker: React.FC<IProps> = props => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [maker, setMaker] = React.useState(props.selectedMaker)
  const [makerList, setMakerList] = React.useState<any[]>(props.makerList)

  const onInputChange = async (inputValue: string) => {
    setMaker(inputValue)
    if (inputValue.length == 0) {
      return
    }
    setIsLoading(true)
    await fetch(`/listing_makers?keyword=${inputValue}`, {
      method: "get"
    }).then((response) => {
      if (!response.ok) {
          throw new Error();
      }
      return response.json()
    }).then((json) => {
      if (json.data != null) {
        setMakerList(json.data)
      }
      setIsLoading(false)
    })
  }

  const defaultValueMaker = () => {
    if (!props.searchObject.attributes.maker_names) {
      return []
    }
    return props.searchObject.attributes.maker_names.map(function( v ) {
      return { label: v,value: v };
    });
  }
  return (
    <Select
      isMulti
      defaultValue={defaultValueMaker()}
      inputValue={maker}
      name='search_listing[maker_names][]'
      options={makerList}
      className="w−52"
      placeholder="メーカーを入力"
      onInputChange={onInputChange}
    />
  )
}

export default SearchSelectMaker