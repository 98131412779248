import React, { Component } from 'react';

interface IProps {
  signd_in: boolean,
  price: number,
  commission: number,
  tax: number,
}

const CalcPrice: React.FC<IProps> = props => {
  const [price, setPrice] = React.useState(props.price)

  const handleChange = (event)  => {
    if(event.target.value == "") {
      setPrice(0)
    }else {
      setPrice(parseInt(event.target.value))
    }
  }
  // 本体価格にかかる消費税のみ端数切捨て（.floor)
  const calcPriceOnTax = () => {
    return Math.floor(price * onTax())
  }
  const calcPriceInTax = () => {
    return calcPriceOnTax() - price
  }
  const calcCommission = () => {
    if (price == undefined) {
      return 0
    }
    return Math.round(price * props.commission)
  }
  const calcCommissionOnTax = () => {
    return Math.round(calcCommission() * onTax())
  }
  const calcProfit = () => {
    if (price == undefined) {
      return 0
    }
    return Math.round(price - calcCommission())
  }
  const calcProfitOnTax = () => {
    return Math.round(calcPriceOnTax() - calcCommissionOnTax())
  }
  const onTax = () => {
    return 1 + props.tax
  }

  return (
    <div className="sell-table">
      <div className="left-table">
        <div className="sell-table-content-l py-4">
          <div className="d-flex align-items-center justify-content-between mx-auto w-90 my-3">
            <div className="font-bold">本体価格</div>
            <input type="number" name="listing[price]" id="listing_price" defaultValue={price} onChange={handleChange} className="form-control w-30 min-w44"/>
            <div className="font-bold">円（税抜）</div>
          </div>
        </div>
        <div className="sell-table-content-l py-8">
          <div className="d-flex justify-content-between mx-auto w-90">
            <div className="content-m">手数料</div>
            <span id="listing_price_commission">{calcCommission().toLocaleString()}</span>
            <span>円（税抜）</span>
          </div>
        </div>
        <div className="sell-table-content-l py-8">
          <div className="d-flex justify-content-between mx-auto w-90">
            <div className="content-m-p">利益</div>
            <span id="listing_price_profit">{calcProfit().toLocaleString()}</span>
            <span>円（税抜）</span>
          </div>
        </div>
      </div>
      <div className="right-table">
        <div className="sell-table-content-r py-8 sell-price">
          <div className="d-flex justify-content-between w-90 mx-auto">
            <div className="font-bold">販売価格</div>
            <span id="listing_price_price_on_tax">{calcPriceOnTax().toLocaleString()}</span>
            <div className="font-bold">円（税込）</div>
          </div>
          <div className="d-flex justify-content-end w-90 mx-auto">
            <span className="mr-3">（内消費税</span>
            <span className="mr-3" id="listing_price_price_in_tax">{calcPriceInTax().toLocaleString()}</span>
            <span>円）</span>
          </div>
        </div>
        <div className="sell-table-content-r py-8">
          <div className="d-flex justify-content-between w-90 mx-auto">
            <div className="content-m">手数料</div>
            <span id="listing_price_commission_on_tax">{calcCommissionOnTax().toLocaleString()}</span>
            <span>円（税込）</span>
          </div>
        </div>
        <div className="sell-table-content-r py-8">
          <div className="d-flex justify-content-between w-90 mx-auto">
            <div className="content-m-p">利益</div>
            <span id="listing_price_profit_on_tax">{calcProfitOnTax().toLocaleString()}</span>
            <span>円（税込）</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalcPrice